import * as React from "react"
import LocalizedPage from "../components/LocalizedPage"

const EsPage = () => {
    return (
        <div>
            <title>Clínica veterinaria La Huella Verde</title>
            <LocalizedPage language="es"></LocalizedPage>
        </div>
    )
};

export default EsPage;